<script>
import CustomerPageBanner from "@/components/customer-page-banner.vue";
import EquipmentSearchPanel from "@/components/equipment-search-panel.vue";
export default {
  name: "EquipmentSearchBase",
  components: {
    CustomerPageBanner,
    EquipmentSearchPanel
  },
  data: function () {
    return {
      isReady: false,
      selectedEquipment: null,
      search: true,
      nItems: {
        available: 0,
        filtered: 0
      },
      refreshEnabled: false,
      sortOrder: null,
      fixedEquipmentProperties: [
        "alarm_status",
        "connection_status",
        "connector",
        "serial_number",
        "location",
        "device",
        "process_area"
      ]
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"] || this.$store.getters["isIdle"];
    },
    contract() {
      return this.$store.getters["user/contract"];
    },
    contract_id() {
      return this?.contract?.id || 0;
    },
    source() {
      let self = this;
      let cfg =
        (self.$root.config && self.$root.config.equipment_selection) || null;
      let source = (cfg && cfg.source) || "device";
      let lst = ((cfg && cfg.tabs && cfg.tabs.items) || []).filter(function (
        i
      ) {
        return i.component == self.$options.name && i.source;
      });
      source = lst.length ? lst[0].source : source;
      return source;
    },
    isConfigurationReady() {
      var lst = this.equipmentList;
      return (lst || []).length > 0;
    },
    equipmentList() {
      // TODO: in order to keep backward compatibility, while able to search on advanced properties;
      // this method has been moved from storage
      const query = this.$store.getters["equipment/query"];
      const processArea = this.$store.getters["equipment/processArea"];
      const entryList = this.$store.getters["equipment/entryList"];
      const selectedDeviceList =
        this.$store.getters["equipment/selectedDeviceList"];
      const source = this.$store.getters["equipment/source"];
      const enabledItems = this.$store.getters["equipment/enabledItems"];
      const qtags = this.$utils.hashtags(query.query_string, true);

      const validateConnector = (item) => {
        var search = false;
        if (qtags.tags.length && (item?.portal_data?.tags || []).length) {
          for (var i in item.portal_data.tags) {
            if (
              qtags.tags.indexOf(
                (item.portal_data.tags[i]?.text || "").toLowerCase()
              ) >= 0
            ) {
              if (qtags.text) {
                search = this.$utils.queryStrAtr(qtags.text, item); // not deep
              } else {
                search = true;
              }
              break;
            }
          }
        } else {
          search = this.$utils.queryStrAtr(query.query_string, item, "any"); // deep search
        }
        return (
          // validate query string
          search &&
          // validate connection state
          (query.query_connection.checked == "all" ||
            (query.query_connection.checked == "connected" &&
              item.is_connected) ||
            (query.query_connection.checked == "disconnected" &&
              !item.is_connected)) &&
          // validate enabled state
          (query.query_connection.disabled ? !item.enabled : true) &&
          // validate alarm state
          (!query.query_alarm ||
            (query.query_alarm && item.has_active_alarms)) &&
          // validate filter object
          (!processArea || !processArea?.ids?.length
            ? true
            : processArea.ids.indexOf(item.id) >= 0)
        );
      };

      const validateDevice = (item) => {
        var search = false;
        if (qtags.tags.length && (item?.portal_data?.tags || []).length) {
          for (var i in item.portal_data.tags) {
            if (
              qtags.tags.indexOf(
                (item.portal_data.tags[i]?.text || "").toLowerCase()
              ) >= 0
            ) {
              if (qtags.text) {
                search = this.$utils.queryStrAtr(qtags.text, item); // not deep
              } else {
                search = true;
              }
              break;
            }
          }
        } else {
          let obj = {},
            vlr;

          (this.fields || [])
            .filter(
              ({visible}) =>
                visible === undefined ||
                visible == true ||
                typeof visible == "function"
            )
            .forEach((i) => {
              vlr = i.parser ? i.parser(item, this) : item[i.name];
              obj[i.name] = vlr === undefined ? "" : `${vlr}`.trim();
            });
          search = this.$utils.queryStrAtr(query.query_string, obj);
          // search = this.$utils.queryStrAtr(query.query_string, item, "any"); // deep search
        }
        var connection =
          query.query_connection.checked == "all" ||
          (query.query_connection.checked == "connected" &&
            (item.is_connected || item.device.is_connected)) ||
          (query.query_connection.checked == "disconnected" &&
            !query.query_connection.disabled &&
            (!item.is_connected || !item.device.is_connected) &&
            (item.enabled || item.device.enabled));

        var disabled =
          query.query_connection.disabled &&
          (!item.enabled || !item.device.enabled);

        return (
          // validate query string
          search &&
          // validate connection state
          // https://hitecnologia.bitrix24.com.br/extranet/workgroups/group/47/tasks/task/view/17804/?IFRAME=Y&IFRAME_TYPE=SIDE_SLIDER&MID=20054#com20054
          (connection ||
            // validate enabled state
            disabled) &&
          // validate alarm state
          (!query.query_alarm ||
            (query.query_alarm && item.device.has_active_alarms)) &&
          // validate filter object

          (!processArea || !processArea?.ids?.length
            ? true
            : processArea.ids.indexOf(item.device.id) >= 0)
        );
      };

      if (entryList.length) {
        if (query && (query.query_string || "").indexOf(":") > 0) {
          return this.$utils.search(this, entryList, query.query_string);
        }
        return source == "device"
          ? selectedDeviceList.filter(validateDevice)
          : source == "connector"
          ? enabledItems.filter(validateConnector)
          : source == "all"
          ? entryList
          : enabledItems;
      }
      return entryList;
    },
    currentPage() {
      return this.$store.getters["currentPage"];
    },
    nContractConnectors() {
      return this.contract ? this.contract?.registered_connectors : 0;
    },
    nConnectors() {
      // usable connector instances (does not consider any model)
      return this.contract
        ? (
            (this.equipmentList || []).filter(({base_model}) => !base_model) ||
            []
          ).length
        : 0;
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    enabledConnectorList() {
      return this.$utils.distinct(
        this.$store.getters["equipment/enabledItems"] || []
      );
    },
    mqttConnectorTopics() {
      let topics = null;
      (this.enabledConnectorList || [])
        .filter(
          ({base_model, mqtt_topic_prefix}) => !base_model && mqtt_topic_prefix
        )
        .forEach(({id, mqtt_topic_prefix}) => {
          topics = topics || {};
          topics[mqtt_topic_prefix] = id;
        });
      return topics;
    },
    equipmentCustomFields() {
      // TODO: this method has been moved from descendent and must be reviewed.
      return (this.$root.config.equipment_selection.cols || []).filter(
        ({enabled, name}) =>
          (enabled === undefined || enabled) &&
          this.fixedEquipmentProperties.indexOf(name) == -1
      );
    },
    fields() {
      return this.defaultFields();
    },
    columns() {
      return (this.fields || []).map((i) => {
        return {
          ...i,
          asc:
            !this.currentSort ||
            !this.currentSort.column ||
            this.currentSort.column != i.name
              ? undefined
              : this.currentSort.asc
        };
      });
    }
  },
  watch: {
    source: {
      handler(n) {
        this.$store.dispatch("equipment/setSource", n); // connector or device
      },
      immediate: true
    },
    refreshEnabled: {
      handler(n, o) {
        let self = this;
        if (n) {
          if (!self._refreshTimer) {
            if (this?.$rt?.mqtt?.status === "CONNECTED") return;
            // begin - test only
            //self?.$root?.config?.dashboard?.refreshInterval=5000;
            // end
            let interval =
              self?.$root?.config?.dashboard?.refreshInterval || 30000;
            self._refreshTimer = setInterval(() => {
              if (self._refreshTimer) {
                self.updateCurrentPage();
                // validate interval changes
                if (
                  interval !=
                  (self?.$root?.config?.dashboard?.refreshInterval || 30000)
                ) {
                  self.refreshEnabled = false;
                  self.$nextTick(() => {
                    self.refreshEnabled = true; // force new config reload
                  });
                }
              }
            }, interval);
          }
        } else {
          if (self._refreshTimer) {
            clearTimeout(self._refreshTimer);
            self._refreshTimer = null;
          }
        }
      },
      immediate: true
    },
    equipmentList: {
      handler(n) {
        if (n) {
          this.$emit("nItemsChanged", {
            scope: this.$store.getters["equipment/source"],
            available: this.$store.getters["equipment/entryList"].length,
            filtered: n.length
          });
        }
      },
      immediate: true
    },
    isLoading: {
      handler(n, o) {
        // this.$emit("loading", n);
        if (o && !n) {
          this.$emit("finished", this.equipmentList.length);
          this.isReady = true;
        }
      },
      immediate: true
    },
    nConnectors: {
      handler(n, o) {
        if (!o && n) {
          this.isReady = true;
          if (this.mqttConnectorTopics) {
            this.setupProtocol();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    showEquipmentWithId(value) {
      var equipment = this.$store.getters.equipmentById(value);
      if (equipment) {
        this.$router.push("/dashboard/equipment/" + value);
      }
    },
    updateCurrentPage() {
      this.$root.$emit("refreshPage");
    },
    onMQTTStatus(status) {
      if (status === "CONNECTED") {
        this.refreshEnabled = false;
        this.$rt.mqtt.subscribeOnlyOnTopics(
          Object.keys(this.mqttConnectorTopics || {}).map((t) => `${t}/#`)
        );
      }
    },
    onMQTTMessage($event) {
      let entry;
      let topic = $event.info.destinationName.replace(
        /\/(connector_state|completed_data_acquisition)/g,
        ""
      );
      let connector = this.enabledConnectorList.find(
        ({mqtt_topic_prefix}) => mqtt_topic_prefix == topic
      );
      if (!connector) return;
      if (/connector_state/.test($event.info.destinationName)) {
        if (connector) {
          entry = {
            id: connector.id,
            ...$event.msg
          };
          this.$store.dispatch("dashboard/setConnectorValue", entry);
          // if ($event.msg.connected && !connector.is_connected) {
          //   this.$nextTick(this.updateCurrentPage);
          // }
        }
      } else if (
        /completed_data_acquisition/.test($event.info.destinationName)
      ) {
        // it is only valid for modbus connectors
        if (
          this._dataRefreshTimer ||
          !connector ||
          connector?.protocol?.is_mqtt_protocol
        )
          return;
        // it skips connection status - since it will be handle by a different topic
        // console.log("completed_data_acquisition");
        if (
          connector?.devices?.length &&
          connector.devices.filter(({is_connected}) => !is_connected).length ==
            connector.devices.length
        ) {
          this.$nextTick(this.updateCurrentPage);
        }
      }
    },
    setupProtocol() {
      if (
        this._mqttObserver ||
        !this?.$rt.mqtt ||
        !this?.mqttConnectorTopics ||
        this.dashboardMode == "editor" ||
        this.mode == "editor"
      )
        return;
      this._mqttObserver = true;
      this.onMQTTStatus(this.$rt.mqtt.status);
      return;
    },
    showField(name) {
      let found = (this.$root.config.equipment_selection.cols || []).find(
        (i) => name == i.name && (!("enabled" in i) || i.enabled)
      );
      return found != undefined;
    },
    defaultFields() {
      return [
        {
          name: "alarm_status",
          title: "alarm",
          visible: this.showField("alarm_status"),
          parser(item) {
            return `${this.$t(
              item.device_has_active_alarms ? "alarmed" : "normal"
            )}`;
          }
        },
        {
          name: "connection_status",
          title: "connection",
          visible: this.showField("connection_status"),
          parser(item) {
            return `${this.$tc(
              item.is_connected ? "connected" : "disconnected",
              1
            )}`;
          }
        },
        {
          name: "name",
          title: "connector",
          visible: this.showField("connector"),
          parser(item) {
            return item.name || "";
          }
        },
        {
          name: "serial_number",
          title: "s_n",
          visible: this.showField("serial_number"),
          parser(item) {
            return item.serial_number || "";
          }
        },
        {
          name: "location_name",
          title: "location",
          visible: this.showField("location"),
          parser(item) {
            return item.location_name || "";
          }
        },
        {
          name: "device_name",
          title: "device",
          visible: this.showField("device"),
          parser(item) {
            return item?.device?.name || item?.device_name || item?.name || "";
          }
        },
        {
          name: "device_process_area_name",
          title: "process_area",
          visible: this.showField("process_area"),
          parser(item) {
            return (
              item?.device?.process_area?.name ||
              item?.device_process_area_name ||
              ""
            );
          }
        }
      ]
        .concat(this.equipmentCustomFields)
        .filter(({visible}) => visible ?? visible);
    }
  },
  beforeCreate() {
    this._onMQTTStatus = ($e) =>
      this.onMQTTStatus && this.onMQTTStatus($e.detail);
    this._onMQTTMessage = ($e) =>
      this.onMQTTMessage && this.onMQTTMessage($e.detail);
    this.$rt.addListener("mqtt:status", this._onMQTTStatus);
    this.$rt.addListener("mqtt:message", this._onMQTTMessage);
  },
  created() {
    this._refreshTimer = null;
    if (!this.nConnectors || this.nContractConnectors >= this.nConnectors) {
      // this.fetchScreens();
      this.$emit("fetchEquipmentList");
    } else if (this.nConnectors) {
      this.$nextTick(() => {
        this.updateCurrentPage();
      });
    }
  },
  beforeDestroy() {
    this.$rt.removeListener("mqtt:status", this._onMQTTStatus);
    this.$rt.removeListener("mqtt:message", this._onMQTTMessage);
    let self = this;
    this.refreshEnabled = false;
    if (self._refreshTimer) {
      clearInterval(self._refreshTimer);
      self._refreshTimer = null;
    }
  },
  mounted() {
    let self = this;
    self.$nextTick(function () {
      $('a[data-toggle="tab"]').on("shown.bs.tab", function (evt) {
        self.search = !(evt.target.href.indexOf("equipment-map") > 0);
      });
    });
    this.$root.$emit("controlSidebar:setContent", null);
  }
};
</script>

<style scoped>
.tab-search {
  padding-bottom: 200px !important;
}

.equipment-item:hover {
  cursor: pointer;
}
.equipment-item .info-box {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.equipment-item .info-box:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.equipment-enabled {
  opacity: 1;
}
</style>
